import { SignupFormikValueProps } from './SignupFormType';

const validateForm = (values: SignupFormikValueProps) => {
    const errors = {} as SignupFormikValueProps;

    if (!values.provider) {
        errors.provider = 'Telecomprovider is vereist';
    }

    if (!values.customerYears) {
        errors.customerYears = 'U dient het aantal jaren in te vullen, kan ook 0 zijn';
    } else if (!parseInt(values.customerYears)) {
        errors.customerYears = 'U dient een geldig getal in te vullen';
    }

    if (!values.firstName || values.firstName.length < 2) {
        errors.firstName = 'U dient een geldige voornaam in te vullen';
    }

    if (!values.lastName) {
        errors.lastName = 'U dient een geldige achternaam in te vullen';
    }

    if(!values.birthYear) {
        errors.birthYear = 'U dient een geldige geboortedatum in te vullen';
    }
    let split = values.birthYear.replaceAll('/', '');
    const containsLetters = /[a-zA-Z]/g.test(split);

    // check if birthyear contains letters
    if(values.birthYear && containsLetters){
        errors.birthYear = 'U dient een geldige geboortedatum in te vullen';
    }

    if (!values.address) {
        errors.address = 'U dient een geldig adres in te vullen';
    }

    if (!values.zipcode || values.zipcode.length < 6 || !(/^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i.test(values.zipcode))) {
        errors.zipcode = 'U dient een geldige postcode in te vullen';
    }

    if (!values.email) {
        errors.email = 'E-mail is vereist';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Dit e-mailadres is niet valide';
    }

    if (!values.phone) {
        errors.phone = 'U dient een geldig telefoonnummer in te vullen';
    }

    if (!values.iban || !(/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/i.test(values.iban))) {
        errors.iban = 'U dient een geldig rekeningnummer in te vullen';
    }

    if (!values.privacyApproval) {
        errors.privacyApproval = 'U dient akkoord te gaan';
    }

    if (!values.termsApproval) {
        errors.termsApproval = 'U dient akkoord te gaan';
    }

    if(!values.gender) {
        errors.gender = "U dient een geslacht aan te selecteren"
    }
    return errors;
};

export { validateForm };
