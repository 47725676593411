import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { EasybaseProvider } from 'easybase-react';
import { HelmetProvider } from 'react-helmet-async';
import "intersection-observer";

const ebconfig = {
    integration: process.env.REACT_APP_EB_INTEGRATION_KEY as string,
    tt: process.env.REACT_APP_EB_TT_KEY as string,
    version: '2020-11-20',
};

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <EasybaseProvider ebconfig={ebconfig}>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </EasybaseProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
