import React from 'react';
import { Helmet } from 'react-helmet-async';
import LazyLoad from 'react-lazyload';
import ContactBanner from '../layout/ContactBanner';

const ServicesPage: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>BezuinigSlim - Service</title>
                <meta
                    name="description"
                    content="Wij besparen op uw internet- en tv abonnement voor een looptijd van maximaal één jaar.
                    Bij een succesvolle besparing berekenen wij 25% servicekosten over de totale jaarlijkse besparing."
                />
            </Helmet>
            <div className="flex">
                <div className="container services">
                    <div className="services__content">
                        <h1>Service</h1>
                        <div className="policies__subtitle">Hoe werkt het?</div>
                        <p>
                            Wij van BezuinigSlim helpen u met het besparen op uw internet- en tv abonnement. Wanneer u zich via onze website aanmeldt,
                            krijgt u hier een bevestiging van in uw mailbox. Onze professionele onderhandelaars gaan dan direct voor u aan de slag.
                            Onze onderhandelaars hebben de bevoegdheid om uw huidige contract te heronderhandelen voor een periode van{' '}
                            <b>maximaal één jaar</b>. Wij zullen daarom nooit de inhoud van uw contract aanpassen.
                        </p>
                        <p>
                            Wanneer een onderhandeling succesvol is verlopen, krijgt u hier bericht van via uw telecom provider. Een succsesvolle
                            onderhandeling betekent dat wij minimaal 30 euro korting hebben bespaard op jaarbasis. Na de onderhandeling heeft u nog
                            veertien dagen bedenktijd. In deze periode kunt u zich herroepen en uw huidige abonnement behouden.
                        </p>
                        <p>
                            Als een onderhandeling succesvol is verlopen, zullen wij u een factuur via de mail sturen. De servicekosten bedragen 25%
                            (incl. BTW) van de totale besparing op jaarbasis. Wanneer een onderhandeling niet goed is verlopen, zullen wij u hierover
                            tijdig informeren via de mail. U hoeft hier uiteraard niks voor te betalen. <b>No cure no pay</b>.
                        </p>
                        <p>
                            Er zijn verschillende oorzaken die kunnen leiden tot een niet succesvolle onderhandeling. De meest voorkomende oorzaken
                            zijn:
                        </p>
                        <ul className="policies__list">
                            <li>U zit nog vast aan een contract bij uw huidige provider.</li>
                            <li>Onze onderhandelaars hebben geen betere deal voor u weten te onderhandelen.</li>
                            <li>De persoonsgegevens die u aan ons heeft doorgegeven zijn incorrect.</li>
                        </ul>
                        <p>
                            Wij zullen u hier altijd tijdig over informeren via de mail, zodat u aan kunt geven of u nogmaals van onze service gebruik
                            wilt maken. Met het streven dat de onderhandeling dan wel succesvol is.{' '}
                        </p>
                        <div className="policies__subtitle">Voorbeeld</div>
                        <p className="services__example">
                            Als één van onze onderhandelaars 100 euro korting op jaarbasis voor u bespaard. Zullen wij u een factuur sturen van 25
                            euro. <b>U bespaart dan maar liefst 75 euro</b>!
                        </p>
                    </div>
                    <div className="services__img">
                        <LazyLoad height={200}>
                            <img src="./call-lady.png" alt="calling lady" />
                        </LazyLoad>
                    </div>
                </div>
            </div>
            <ContactBanner
                title="Contact"
                text="Als u vragen heeft kunt u ons natuurlijk altijd bereiken!"
                urlText="Neem contact op"
                bgImg="forest.jpg"
            />
        </>
    );
};

export default ServicesPage;
