import React from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';

export type ContactBannerProps = {
    title: string;
    text: string;
    urlText: string;
    bgImg: string;
};

const ContactBanner: React.FC<ContactBannerProps> = (props: ContactBannerProps) => {
    return (
        <LazyLoad height={200}>
            <section className="contact-banner" style={{ backgroundImage: `url('./${props.bgImg}')` }}>
                <div className="flex">
                    <div className="container flex-column">
                        <h2 style={{marginTop: '50px'}}>{props.title}</h2>
                        <p>{props.text}</p>
                        <Link to="/contact" className="button-sharp" style={{marginBottom: '50px'}}>
                            {props.urlText}
                        </Link>
                    </div>
                </div>
            </section>
        </LazyLoad>
    );
};
export default ContactBanner;
