import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useWindowWidth } from '@react-hook/window-size';

type CircleStepProps = {
    title: string;
    icon?: IconDefinition;
    titleIcon?: IconDefinition;
    text?: string;
    randomOffset?: boolean;
    children?: React.ReactNode;
};

const CircleStep: React.FC<CircleStepProps> = (props: CircleStepProps) => {
    const windowWith = useWindowWidth();
    const isMobile = windowWith < 768;
    const randomOffset = props.randomOffset && Math.round(Math.random() * 100);

    return (
        <div className="circlestep" style={randomOffset && !isMobile ? { marginTop: randomOffset } : {}}>
            {props.icon && (
                <div className="circlestep__circle flex draw">
                    <FontAwesomeIcon icon={props.icon} />
                </div>
            )}
            <div className="circlestep__title">
                {props.titleIcon && <FontAwesomeIcon icon={props.titleIcon} />}
                {props.title}
            </div>
            {props.text && (
                <p className="circlestep__text">{props.text}</p>
            )}
            {props.children ? props.children : ''}
        </div>
    );
};

export default CircleStep;
