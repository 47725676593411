import React from 'react';

const CookiePolicy: React.FC = () => {
    return (
        <>
            <div className="policies__subtitle">BezuinigSlim Cookiebeleid:</div>
            BezuinigSlim maakt gebruik van cookies. Onze cookies zijn niet persoonlijk herleidbaar en niet schadelijk voor uw apparaat . Cookies zijn
            nodig voor het optimaal functioneren van de website van BezuinigSlim. Cookies zijn enkel persoonlijk herleidbaar, als u hier expliciet mee
            akkoord gaat. U kunt hiermee akkoord gaan door dit aan te geven onderaan uw scherm.
            <div className="policies__subtitle">Gebruik van cookies:</div>
            Wij, van BezuinigSlim, gebruiken cookies om inzage te hebben in hoe onze website gebruikt wordt door gebruikers. Wij kunnen ook cookies
            gebruiken om fouten op te sporen. Wij zullen hiervoor altijd uw toestemming vragen.
            <div className="policies__subtitle">Hoe lang blijven cookies staan?</div>
            De meeste cookies verdwijnen wanneer u de browser sluit. Andere cookies blijven enkele dagen tot jaren staan. Deze cookies zijn eenvoudig
            te verwijderen.
            <div className="policies__subtitle">Cookies verwijderen of uitschakelen:</div>
            Cookies kunt u op elk moment verwijderen. Dit kan eenvoudig gedaan worden via uw browserinstellingen. U ontvangt dan geen cookies meer
            tijdens uw bezoek aan BezuinigSlim.
            <div className="policies__subtitle">Met wie delen we data?</div>
            BezuinigSlim deelt anonieme data met Google Analytics, Facebook en Google Ads. Voor meer informatie verwijs ik u naar de
            privacyverklaring.
        </>
    );
};

export default CookiePolicy;
