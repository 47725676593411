import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

export type FooterProps = {
    setIsLoading: (loading: boolean) => void;
};

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
    useEffect(() => {
        props.setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <footer className="footer flex">
            <div className="footer__links container">
                <ul>
                    <h4>Ons beleid</h4>
                    <li>
                        <Link to="/databeleid">
                            <FontAwesomeIcon icon={faCaretRight} />
                            Databeleid
                        </Link>
                    </li>
                    <li>
                        <Link to="/privacyverklaring">
                            <FontAwesomeIcon icon={faCaretRight} />
                            Privacyverklaring
                        </Link>
                    </li>
                    <li>
                        <Link to="/algemenevoorwaarden">
                            <FontAwesomeIcon icon={faCaretRight} />
                            Algemene voorwaarden
                        </Link>
                    </li>
                    <li>
                        <Link to="/cookiebeleid">
                            <FontAwesomeIcon icon={faCaretRight} />
                            Cookiebeleid
                        </Link>
                    </li>
                    <div className="bottom-border-gradient"></div>
                </ul>

                <ul>
                    <h4>Algemene pagina's</h4>
                    <li>
                        <Link to="/">
                            <FontAwesomeIcon icon={faCaretRight} />
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/Service">
                            <FontAwesomeIcon icon={faCaretRight} />
                            Service
                        </Link>
                    </li>
                    <li>
                        <Link to="/aanmelden">
                            <FontAwesomeIcon icon={faCaretRight} />
                            Aanmelden
                        </Link>
                    </li>
                    <li>
                        <Link to="/Contact">
                            <FontAwesomeIcon icon={faCaretRight} />
                            Contactformulier
                        </Link>
                    </li>
                    <div className="bottom-border-gradient"></div>
                </ul>

                <ul>
                    <h4>Contact</h4>
                    <li>
                        <a href="mailto:info@bezuinigslim.nl">info@bezuinigslim.nl</a>
                    </li>
                    <li>KVK nummer: 81985592</li>
                    <div className="bottom-border-gradient"></div>
                    {/* <img src="./smalltree.jpg" /> */}
                </ul>
            </div>
            <div className="footer__socials container">
                <ul>
                    <li>
                        <a href="https://www.facebook.com/BezuinigSlim-100227905410428/">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                    </li>
                </ul>
            </div>
            <div className="footer__copyright container">BezuinigSlim - © 2021</div>

            <LazyLoad height={200}>
                <img src="./walkingman.jpg" className="footer__image" alt="walking man" />
            </LazyLoad>
        </footer>
    );
};

export default Footer;
