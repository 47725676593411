import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validateForm } from './validateForm';
import { Api, ApiPostStatus } from '../Api/Api';

export type ContactFormValueProps = {
    email: string;
    name: string;
    phone?: string;
    message: string;
    approval: boolean;
    route?: string;
};

const formikInitialValues: ContactFormValueProps = {
    email: '',
    name: '',
    phone: '',
    message: '',
    approval: false,
};

const ContactForm: React.FC = () => {
    const [submitted, setSubmitted] = useState(false);
    const [hasError, setHasError] = useState(false);

    const sendFormData = (values: ContactFormValueProps) => {
        Api()
            .sendContactData(values)
            .then((response) => {
                if (response.status === ApiPostStatus.SUCCESS) {
                    setSubmitted(true);
                } else {
                    setHasError(true);
                }
            })
            .catch(() => {
                setHasError(true);
            });
    };

    return (
        <div className="contact-form">
            {!submitted && !hasError ? (
                <>
                    <h2>Contactformulier</h2>
                    <p>Vul hierbeneden ons contactformulier in. We nemen zo snel mogelijk contact met je op.</p>
                    <Formik
                        initialValues={formikInitialValues}
                        validate={(values) => validateForm(values)}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                sendFormData(values);
                                setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="contact-form__field">
                                    <Field type="name" name="name" placeholder="Naam" />
                                    <ErrorMessage name="name" component="div" className="contact-form__error" />
                                </div>
                                <div className="contact-form__field">
                                    <Field type="phone" name="phone" placeholder="Telefoonnummer (optioneel)" />
                                    <ErrorMessage name="phone" component="div" className="contact-form__error" />
                                </div>
                                <div className="contact-form__field">
                                    <Field type="email" name="email" placeholder="Uw e-mailadres " />
                                    <ErrorMessage name="email" component="div" className="contact-form__error" />
                                </div>
                                <Field component="textarea" type="message" name="message" placeholder="Uw bericht " />
                                <div className="contact-form__submit">
                                    <div className="contact-form__approve">
                                        <p>
                                            Wanneer u ons contactformulier verzendt verwerken wij de door u ingevulde gegevens. U dient hiermee
                                            akkoord te gaan:
                                        </p>
                                        <Field type="checkbox" name="approval" />
                                        <label style={{ marginLeft: '10px' }}>Ik ga akkoord</label>
                                        <ErrorMessage name="approval" component="div" className="contact-form__error contact-form__error--block" />
                                    </div>
                                    <button type="submit" disabled={isSubmitting} className="button-submit">
                                        Verzenden
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            ) : submitted && !hasError ? (
                <>
                    <h2>Het formulier is succesvol ontvangen.</h2>
                    <p>Wij nemen zo spoedig mogelijk contact met u op!</p>
                </>
            ) : null}

            {hasError && (
                <>
                    <h2>Oeps, er ging iets fout...</h2>
                    <p>Probeer het later nog een keer.</p>
                </>
            )}
        </div>
    );
};

export default ContactForm;
