import React from 'react';
import Banner from '../layout/Banner';
import CircleStep from '../layout/CircleStep';
import { faFileAlt, faHeadset, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { Fade } from 'react-awesome-reveal';
import Divider from '../layout/Divider';
import ContactBanner from '../layout/ContactBanner';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Review from '../Review/Review';

const HomePage: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>BezuinigSlim - Home</title>
                <meta
                    name="description"
                    content="Algemene informatie over BezuinigSlim met betrekking tot de dienst die wij aanbieden.
                    Wij onderhandelen uw huidige internet- en tv abonnement, bij geen succesvolle onderhandeling géén kosten!"
                />
            </Helmet>
            <div className="homepage">
                <Banner />
                <section className="homepage__steps gap">
                    <div className="flex">
                        <div className="container flex-even circlesteps" style={{ marginTop: '-0px', zIndex: 16 }}>
                            <Fade cascade fraction={1} damping={0.2} triggerOnce={true} direction="left">
                                <CircleStep icon={faFileAlt} title="Stap 1" text="Meld u aan via het inschrijfformulier." />
                                <CircleStep
                                    icon={faHeadset}
                                    title="Stap 2"
                                    text="Onze financieel adviseurs onderhandelen uw contract."
                                    randomOffset={true}
                                />
                                <CircleStep
                                    icon={faMoneyBillWave}
                                    title="Stap 3"
                                    text="Wanneer de onderhandeling succesvol is, sturen wij u een factuur voor 25% van de besparing."
                                />
                            </Fade>
                        </div>
                    </div>
                </section>
                <section className="homepage__core-values gap">
                    <div className="flex">
                        <div className="container">
                            <h2>Wat je van ons kan verwachten</h2>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="container flex-even flex-column">
                            <CircleStep
                                title="Snel & eenvoudig"
                                text="Binnen enkele minuten heeft u tot wel €250,- korting op uw telecom abonnement.
                                Het enige dat u hiervoor hoeft te doen, is het inschrijfformulier invullen. De rest doen wij!"
                            />
                            <CircleStep title="Betrouwbaar">
                                <p className="circlestep__text">
                                    Wij gaan uiterst voorzichtig om met uw persoonlijke gegevens. Wij kunnen en zullen uw gegevens niet verkopen aan
                                    derden. Zie voor meer informatie onze{' '}
                                    <Link className="inline-link" to="/privacyverklaring" target="_blank">
                                        privacy verklaring
                                    </Link>
                                    .
                                </p>
                            </CircleStep>
                            <CircleStep
                                title="No cure no pay"
                                text="Wanneer onze onderhandelaars geen goede deal voor u kunnen onderhandelen,
                                worden hier geen kosten voor in rekening gebracht. No cure no pay."
                            />
                        </div>
                    </div>
                </section>

                <section className="flex gap">
                    <div className="container justify-content-center wrap">
                        <Review
                            name="Jeroen Nagtegaal"
                            review="Binnen een paar dagen had ik al een reactie en een besparing van ruim € 120 voor dit jaar. Erg fijn. Nog wel even een vraag over het nieuwe contract, maar ook die was vlot beantwoord. Prima tevreden dus."
                            rating={4}
                            date="1 april 2021"
                            linkTo="https://www.facebook.com/jeroen.nagtegaal1/posts/3775640639194058"
                        />
                        <Review
                            name="Anja Wegman"
                            review="Ben blij dat ik me bij BezuinigSlim heb aangemeld. Door hun heb ik een maand gratis gekregen t.w.v 70 euro. Dit is een TOP regeling. Erg blij mee.Ik beveel het iedereen aan om dit door hun te laten regelen."
                            rating={5}
                            date="29 maart 2021"
                            linkTo="https://www.facebook.com/anja.wegman/posts/4087752497954411"
                        />
                        <Review
                            name="Nicoline Bos"
                            review="Wat een gave service is dit!!! Ze hebben 180 euro besparing per jaar geregeld voor mijn moeder bij Ziggo en ik kan Formule 1 blijven kijken op de Ziggo app 👍 alles snel en binnen een paar uur geregeld. Top bedrijf!"
                            rating={5}
                            date="25 maart 2021"
                            linkTo="https://www.facebook.com/nicoline.bos.37/posts/3845274645539258"
                        />
                    </div>
                </section>

                <ContactBanner
                    title="Contact"
                    text="Als u vragen heeft kunt u ons natuurlijk altijd bereiken!"
                    urlText="Neem contact op"
                    bgImg="wolves.jpg"
                />

                <Divider text="Niet gevonden wat u zocht? Hieronder meer!" height={150} />
            </div>
        </>
    );
};

export default HomePage;
