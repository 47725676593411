import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { SignupFormState } from './SignupFormState';
import { SignupFormikValueProps } from './SignupFormType';
import { validateForm } from './validateSignupForm';
import { Api } from '../Api/Api';
import { Link } from 'react-router-dom';
import { useEasybase } from 'easybase-react';
import { StatusResponse } from 'easybase-react/dist/types';
import InputMask from 'react-input-mask';

const SignupForm: React.FC = () => {
    const [submitted, setSubmitted] = useState(false);
    const [hasError, setHasError] = useState(false);
    const { Frame, sync } = useEasybase();

    const sendFormData = (data: SignupFormikValueProps) => {
        Frame().push(data);

        sync().then((response: StatusResponse) => {
            if (response.success) {
                setSubmitted(true);

                Api().sendSignupData(data); // send email on successful save
            } else {
                setHasError(true);
            }
        });
    };

    return (
        <div className="signup-form">
            {!submitted && !hasError ? (
                <>
                    <Formik
                        initialValues={SignupFormState}
                        validate={(values) => validateForm(values)}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                sendFormData(values);
                                setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="signup-form__field">
                                    <label>Uw huidige telecomprovider</label>
                                    <Field type="provider" name="provider" placeholder="Provider" />
                                    <ErrorMessage name="provider" component="div" className="signup-form__error" />
                                </div>
                                <div className="signup-form__field">
                                    <label>Geslacht</label>
                                    <div className="signup-form__radiogroup">
                                        <label>
                                            <Field type="radio" name="gender" value={'M'} />
                                            Man
                                        </label>
                                        <label>
                                            <Field type="radio" name="gender" value="V" />
                                            Vrouw
                                        </label>
                                    </div>
                                    <ErrorMessage name="gender" component="div" className="signup-form__error" />
                                </div>
                                <div className="signup-form__field">
                                    <label>Hoelang bent u al klant bij uw Telecom provider (jaren)</label>
                                    <Field type="customerYears" name="customerYears" placeholder="15" />
                                    <ErrorMessage name="customerYears" component="div" className="signup-form__error" />
                                </div>
                                <div className="signup-form__field">
                                    <label>Voornaam</label>
                                    <Field type="firstName" name="firstName" placeholder="Voornaam" />
                                    <ErrorMessage name="firstName" component="div" className="signup-form__error" />
                                </div>
                                <div className="signup-form__field">
                                    <label>Achternaam</label>
                                    <Field type="lastName" name="lastName" placeholder="Achternaam" />
                                    <ErrorMessage name="lastName" component="div" className="signup-form__error" />
                                </div>
                                <div className="signup-form__field">
                                    <label>Geboortedatum</label>
                                    <Field name="birthYear">
                                        {({field}: any) => (
                                            <InputMask {...field} mask="99/99/9999" maskPlaceholder="dd/mm/jjjj" placeholder="dd/mm/jjjj"/>
                                        )}
                                    </Field>
                                    <ErrorMessage name="birthYear" component="div" className="signup-form__error" />
                                </div>
                                <div className="signup-form__field">
                                    <label>Adres</label>
                                    <Field type="address" name="address" placeholder="Adres" />
                                    <ErrorMessage name="address" component="div" className="signup-form__error" />
                                </div>
                                <div className="signup-form__field">
                                    <label>Postcode</label>
                                    <Field type="zipcode" name="zipcode" placeholder="Postcode" />
                                    <ErrorMessage name="zipcode" component="div" className="signup-form__error" />
                                </div>
                                <div className="signup-form__field">
                                    <label>E-mailadres</label>
                                    <Field type="email" name="email" placeholder="Uw e-mailadres" />
                                    <ErrorMessage name="email" component="div" className="signup-form__error" />
                                </div>
                                <div className="signup-form__field">
                                    <label>Telefoonnummer</label>
                                    <Field type="phone" name="phone" placeholder="Telefoonnummer" />
                                    <ErrorMessage name="phone" component="div" className="signup-form__error" />
                                </div>
                                <div className="signup-form__field">
                                    <label>Rekeningnummer</label>
                                    <Field type="iban" name="iban" placeholder="NL69INGB0123456789" />
                                    <ErrorMessage name="iban" component="div" className="signup-form__error" />
                                </div>
                                <small>
                                    <i>
                                        Wij hebben uw bankrekeningnummer nodig voor het onderhandelen van uw abonnement. Wij kunnen en zullen uw
                                        bankrekening niet delen, of voor andere doeleinden gebruiken.
                                    </i>
                                </small>

                                <div className="signup-form__submit">
                                    <div className="signup-form__approve">
                                        <label>
                                            <Field type="checkbox" name="privacyApproval" />
                                            Ik heb de&nbsp;
                                            <Link to="/privacyverklaring" target="_blank" className="inline-link">
                                                privacy verklaring
                                            </Link>
                                            &nbsp;gelezen en ik ga hiermee akkoord.
                                        </label>
                                        <ErrorMessage
                                            name="privacyApproval"
                                            component="div"
                                            className="signup-form__error signup-form__error--block"
                                        />
                                    </div>
                                    <div className="signup-form__approve">
                                        <label>
                                            <Field type="checkbox" name="termsApproval" />
                                            Ik heb de&nbsp;
                                            <Link to="/algemenevoorwaarden" target="_blank" className="inline-link">
                                                algemene voorwaarden
                                            </Link>
                                            &nbsp;gelezen en ik ga hiermee akkoord.
                                        </label>
                                        <ErrorMessage name="termsApproval" component="div" className="signup-form__error signup-form__error--block" />
                                    </div>
                                    <button type="submit" disabled={isSubmitting} className="button-submit">
                                        Verzenden
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            ) : !hasError && submitted ? (
                <div className="signup__notification">
                    <h2>Bedankt voor uw aanmelding!</h2>
                    <p>Onze onderhandelaars gaan zo snel mogelijk voor u aan de slag!</p>
                </div>
            ) : null}

            {hasError && (
                <div className="signup__notification">
                    <h2>Oeps, er ging iets fout...</h2>
                    <p>Probeer het later nog een keer.</p>
                </div>
            )}
        </div>
    );
};

export default SignupForm;
