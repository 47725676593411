import React from 'react';
import ContactForm from '../ContactForm/ContactForm';
import { Helmet } from 'react-helmet-async';

const ContactPage: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>BezuinigSlim - Contact</title>
                <meta name="description" content="Het contactformulier, hiervan kunt u gebruik maken om direct met ons in contact te komen." />
            </Helmet>
            <div className="flex">
                <div className="container contact-page">
                    <ContactForm />
                </div>
            </div>
        </>
    );
};

export default ContactPage;
