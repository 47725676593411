import React from 'react';
import { Bounce } from 'react-awesome-reveal';

export type DividerProps = {
    text: string;
    height?: number;
};

const standardHeight = 200;

const Divider: React.FC<DividerProps> = (props: DividerProps) => {
    const height = props.height ? props.height : standardHeight;
    return (
        <div className="divider">
            <div className="flex" style={{ height: `${height}px` }}>
                {props.text && (
                    <div className="container justify-content-center">
                        <Bounce fraction={1} damping={0.2} triggerOnce={true} delay={1000}>
                            <h3 style={{textAlign: 'center'}}>{props.text}</h3>
                        </Bounce>
                    </div>
                )}
            </div>
            <div className="flex">
                <div className="divider__rect">{/* Rectangle for the arrow effect */}</div>
            </div>
        </div>
    );
};

export default Divider;
