import { SignupFormikValueProps } from "./SignupFormType";

export const SignupFormState: SignupFormikValueProps = {
    provider: '',
    customerYears: '',
    gender: '',
    firstName: '',
    lastName: '',
    birthYear: '',
    address: '',
    zipcode: '',
    email: '',
    phone: '',
    iban: '',
    privacyApproval: false,
    termsApproval: false
}
