import React from 'react';

const DataPolicy: React.FC = () => {
    return (
        <>
            <div className="policies__subtitle">
                Uw privacy en veiligheid staan bij BezuinigSlim altijd op de eerste plek. Hierin bieden wij volledige transparantie:
            </div>
            <p style={{ marginTop: '20px' }}>
                1. Wij verkopen uw gegevens nooit. Dit geldt voor al uw persoonlijke gegevens. Wij vermelden duidelijk met welke doeleinden
                persoonsgegevens worden verwerkt binnen BezuinigSlim. Hiervoor vragen wij specifiek uw toestemming in onze privacy verklaring.
            </p>
            <p style={{ marginTop: '20px' }}>2. Wij garanderen de hoogst mogelijke beveiligingsmaatregelen om uw persoonsgegevens te beschermen.</p>
            <p style={{ marginTop: '20px' }}>3. Wij zullen wanneer u dat vraagt uw persoonsgegevens corrigeren, verwijderen of ter inzage bieden.</p>
            <p style={{ marginTop: '20px' }}>
                In onze privacyverklaring wordt u geïnformeerd over de persoonsgegevens die BezuinigSlim verwerkt, voor welke doeleinden deze worden
                verwerkt en welke rechten u heeft.
            </p>
        </>
    );
};

export default DataPolicy;
