import React from 'react';
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

const Banner: React.FC = () => {
    return (
        <div className="banner">
            <img src="./office.jpg" alt="Office" />
            <div className="banner__content">
                <h1 style={{display: 'none'}}>Welkom bij BezuinigSlim</h1>
                <Typewriter
                    onInit={(typeWriter) => {
                        typeWriter.typeString('Welkom bij BezuinigSlim').start();
                    }}
                />
                <p>Wij onderhandelen uw huidige internet- en tv abonnement, bij geen succesvolle onderhandeling géén kosten!</p>
                <div>
                    <Link to="/service" className="button button--white">
                        Hoe werkt het
                    </Link>
                    <Link to="/aanmelden" className="button button--purple">
                        Meld je aan
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Banner;
