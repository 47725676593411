import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import StarRating from 'react-svg-star-rating';

export type ReviewProps = {
    imageUrl?: string;
    name: string;
    rating: number;
    review: string;
    date: string;
    linkTo: string;
};

export const Review: React.FC<ReviewProps> = (props: ReviewProps) => {
    return (
        <div className="review">
            {props.imageUrl && (
                <img className="review__image" src={`${props.imageUrl}`} alt="profile pic" />
            )}
            <h3>{props.name}</h3>
            <StarRating count={5} initialRating={props.rating} isReadOnly={true} />
            <p className="review__text">
                <a href={props.linkTo} target="_blank" rel="noreferrer">
                    {props.review}
                </a>
            </p>
            <div className="review__date">
                <FontAwesomeIcon icon={faFacebook} />
                <small>{props.date}</small>
            </div>
        </div>
    );
};

export default Review;
