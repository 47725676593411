import React, { useEffect, useState } from 'react';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import '../styles/components/_index.scss';
import ContactPage from './pages/ContactPage';
import SignupPage from './pages/SignupPage';
import PoliciesPage from './pages/PoliciesPage';
import { PolicyTypes } from './Policies/PolicyTypes';

export type RouteType = {
    path: string;
    name: string;
    Component: React.FunctionComponent | any;
};

export const headerRoutesTypes = {
    HOME: 'Home',
    SERVICE: 'Service',
    CONTACT: 'Contact',
    SIGNUP: 'Aanmelden',
};

const headerRoutes = [
    { path: '/home', name: headerRoutesTypes.HOME, Component: HomePage },
    { path: '/service', name: headerRoutesTypes.SERVICE, Component: ServicesPage },
    { path: '/contact', name: headerRoutesTypes.CONTACT, Component: ContactPage },
] as RouteType[];

const allRoutes = headerRoutes.concat([
    { path: '/aanmelden', name: 'Aanmelden', Component: SignupPage },
    { path: '/databeleid', name: PolicyTypes.DATA, Component: PoliciesPage },
    { path: '/privacyverklaring', name: PolicyTypes.PRIVACY, Component: PoliciesPage },
    { path: '/algemenevoorwaarden', name: PolicyTypes.TERMS, Component: PoliciesPage },
    { path: '/cookiebeleid', name: PolicyTypes.COOKIES, Component: PoliciesPage },
    { path: '/', name: headerRoutesTypes.HOME, Component: HomePage },
]);

const App: React.FC = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div className={`app-container ${isLoading ? 'loading' : ''}`}>
            <Header activeItem={location.pathname} routes={headerRoutes} />
            {allRoutes.map(({ path, Component, name }) => (
                <Route key={path} exact path={path}>
                    {({ match }) => (
                        <CSSTransition in={match !== null} timeout={400} classNames="page" unmountOnExit>
                            <div className="page">
                                <Component name={name} />
                            </div>
                        </CSSTransition>
                    )}
                </Route>
            ))}
            <Footer setIsLoading={setIsLoading} />
        </div>
    );
};

export default App;
