const Terms = () => {
    return (
        <>
            <div className="policies__subtitle">
                Gebruik van www.bezuinigslim.nl en de diensten die hierop aangeboden worden, is onder voorwaarde van: de gebruiksvoorwaarden.
                Hieronder uiteengezet en de bezuinigslim privacyverklaring.
            </div>
            <div className="policies__subtitle">1. Beschrijving Dienst en Aanbieder</div>
            <p>
                BezuinigSlim maakt het mogelijk om via onze dienstverlening geld te besparen op uw internet- en tv abonnement. Onze onderhandelaars
                nemen voor u contact op met uw internet- en tv abonnement aanbieder om uw huidige contract te heronderhandelen. U meldt zich aan via
                onze website en wij doen de rest. BezuinigSlim is een eenmanszaak met het adres Hunthum 39 3632 XM Nederland, geregistreerd bij de
                Kamer van Koophandel onder nummer 81985592.
            </p>
            <div className="policies__subtitle">2. Toepasselijkheid</div>
            <p>
                Deze gebruiksvoorwaarden zijn van toepassing op alle diensten die BezuinigSlim verleent aan de gebruiker. Wanneer nodig heeft
                BezuinigSlim het recht om deze diensten door derden te laten verrichten.
            </p>
            <div className="policies__subtitle">3. Totstandkoming en uitvoering van Service</div>
            <div className="policies__subtitle">3.1 Huidige abonnement heronderhandelen</div>
            <p>
                Deze service van BezuinigSlim maakt het mogelijk voor consumenten om hun huidige internet- en tv abonnement te laten heronderhandelen.
                Door je aan te melden via het inschrijfformulier op de website, geef je BezuinigSlim het recht om het huidige internet- en tv
                abonnement te heronderhandelen. Wanneer een contract succesvol is heronderhandeld bent u verplicht de servicekosten die BezuinigSlim
                in rekening brengt te betalen. Een onderhandeling is succesvol wanneer BezuinigSlim een besparing op uw maandlasten heeft
                gerealiseerd. De servicekosten bedragen 25 procent van het totaal bespaarde bedrag op jaarbasis. Deze overeenkomst komt tot stand
                wanneer de gebruiker het formulier heeft ingevuld, alle voorwaarden accepteert en op ‘meld je aan’ klikt.
            </p>
            <div className="policies__subtitle">4. Contractduur</div>
            <p>
                De contractduur van deze overeenkomst is 30 dagen. Dit betekent dat na het invullen van het formulier en het accepteren van de
                voorwaarden. BezuinigSlim het recht heeft om in deze tijdsperiode uw contract te heronderhandelen. Wanneer er spraken is van een
                openstaande factuur bij de client zal het contract pas verbroken worden wanneer deze betaald is.
            </p>
            <div className="policies__subtitle">5. Tarief</div>
            <p>
                Wanneer een contract succesvol is heronderhandeld, berekent BezuinigSlim servicekosten van 25% inclusief BTW. over de totale
                jaarlijkse besparing. Als er geen besparing heeft plaatsgevonden, zullen er geen servicekosten in rekening gebracht worden. No cure no
                pay.
            </p>
            <div className="policies__subtitle">6. Persoonsgegevens en Privacyverklaring</div>
            <p>
                De informatie die u deelt met BezuinigSlim, wordt behandeld volgens BezuinigSlim ’s Privacyverklaring. Deze Privacyverklaring is te
                vinden onder het kopje Privacyverklaring.
            </p>
            <div className="policies__subtitle">7. Rechten van intellectuele eigendom</div>
            <p>
                De informatie die de cliënt deelt met BezuinigSlim heeft een beperkt gebruiksrecht. BezuinigSlim mag deze informatie geaggregeerd en
                geanonimiseerd verwerken voor interne doeleinden. Deze data is op geen enkele wijze te herleiden naar een persoon. Client kan dit
                gebruiksrecht intrekken. Gebruiksrecht kunt u intrekken door dit schriftelijk kenbaar te maken aan BezuinigSlim. Voor verdere
                informatie verwijzen wij u naar de Privacyverklaring.
            </p>
            <div className="policies__subtitle">8. Aansprakelijkheid</div>
            <p>
                BezuinigSlim is niet aansprakelijk voor enige schade die voortvloeit uit de toegang tot, of het gebruik van deze website en garandeert
                niet dat de website ononderbroken zal functioneren. Hoewel BezuinigSlim er alles aan doet om de inhoud, informatie en materialen die
                op deze website worden weergegeven, zo actueel en volledig mogelijk te houden, kan deze geen enkele garantie bieden, voor de
                juistheid, geldigheid, volledigheid of actualiteit van de informatie die op deze website wordt gepubliceerd of waartoe deze website
                toegang geeft. BezuinigSlim aanvaardt in dat opzicht geen enkele aansprakelijkheid.
            </p>
            <div className="policies__subtitle">9. Feedback</div>
            <p>
                BezuinigSlim stelt het zeer op prijs als u ons op de hoogte stelt van vragen, problemen of klachten. Dit stelt ons in de gelegenheid
                een passende oplossing te vinden. Het geeft ons ook de mogelijkheid om onze service voor u en anderen te verbeteren. Neem gerust
                contact met ons op via onderstaand mailadres: info@BezuinigSlim.nl
            </p>
            <div className="policies__subtitle">10. Overmacht</div>
            <p>
                Onder overmacht wordt verstaan: elke omstandigheid op grond waarvan (verdere) nakoming van de Overeenkomst door BezuinigSlim
                redelijkerwijs niet kan worden verlangd. In het geval dat BezuinigSlim door overmacht wordt verhinderd de Diensten geheel of
                gedeeltelijk te verlenen, heeft BezuinigSlim het recht om zonder gerechtelijke tussenkomst de uitvoering van de Diensten op te
                schorten of de Overeenkomst geheel of gedeeltelijk als ontbonden te beschouwen, zulks te zijner keuze.
            </p>
            <div className="policies__subtitle">11. Wijzigingen</div>
            <p>
                BezuinigSlim behoudt zich het recht voor om deze algemene voorwaarden op elk moment te wijzigen. Bij wijzigingen worden alle
                gebruikers per e-mail hierop geattendeerd.
            </p>
            <div className="policies__subtitle">12. Overdracht rechten en plichten</div>
            <p>
                Zowel BezuinigSlim als de client is niet gerechtigd de rechten en plichten die voortkomen uit deze gebruiksvoorwaarden aan derden over
                te dragen.
            </p>
            <div className="policies__subtitle">13. Partiële nietigheid</div>
            <p>
                Indien een bepaling uit de gebruiksvoorwaarden nietig blijkt te zijn, tast dit niet de geldigheid van de gehele gebruiksvoorwaarden
                aan. BezuinigSlim zal ter vervanging (een) nieuwe bepaling(en) vaststellen, waarmee zoveel als rechtens mogelijk is aan de bedoeling
                van de oorspronkelijke gebruiksvoorwaarden gestalte wordt gegeven.
            </p>
            <div className="policies__subtitle">14. Slotbepalingen</div>
            <p>
                Op deze gebruiksvoorwaarden is Nederlands recht van toepassing. Alle geschillen tussen een gebruiker en BezuinigSlim die naar
                aanleiding van of in verband met deze overeenkomst mochten ontstaan, zullen in beginsel worden beslecht door de bevoegde rechter in
                Amsterdam. Deze gebruiksvoorwaarden zijn van kracht met ingang van 17 januari 2021.
            </p>
        </>
    );
};

export default Terms;
