import { ContactFormValueProps } from '../ContactForm/ContactForm';
import { SignupFormikValueProps } from '../SignupForm/SignupFormType';

const serverUrl = process.env.REACT_APP_MAIL_FUNCTION;

export type ApiClient = {
    sendSignupData: (data: SignupFormikValueProps) => Promise<ApiResponse>;
    sendContactData: (data: ContactFormValueProps) => Promise<ApiResponse>;
};

export const ApiPostStatus = {
    SUCCESS: "success",
    FAILED: "failed"
}

export type ApiResponse = {
    status: string
}

export const Api = (): ApiClient => {
    const sendSignupData = async (data: SignupFormikValueProps): Promise<ApiResponse> => {
        data.route = 'signup';

        const response = await fetch(`${serverUrl}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return await response.json();
    };

    const sendContactData = async (data: ContactFormValueProps): Promise<ApiResponse> => {
        data.route = 'contact';

        const response = await fetch(`${serverUrl}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return await response.json();
    };

    return {
        sendSignupData,
        sendContactData,
    };
};
