import React from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
    return (
        <>
            <div className="policies__subtitle">BezuinigSlim Privacyverklaring:</div>
            <p>
                Laatste versie: januari 2021. Doormiddel van deze Privacyverklaring willen wij u informeren over de persoonsgegevens die BezuinigSlim
                verwerkt, voor welke doeleinden deze worden verwerkt en welke rechten je hebt.
            </p>
            <div className="policies__subtitle">BezuinigSlim Privacyverklaring in het kort:</div>
            <p>
                Wij zullen altijd duidelijk vermelden met welke doeleinden wij persoonsgegevens verwerken. Dat doen wij via deze Privacyverklaring;
                Wij beperken onze verzameling van persoonsgegevens tot alleen de persoonsgegevens die nodig zijn voor de genoemde en geaccordeerde
                doeleinden; Wij vragen altijd om uitdrukkelijke toestemming om uw persoonsgegevens te verwerken in gevallen waarin uw toestemming is
                vereist; Wij nemen passende beveiligingsmaatregelen om uw persoonsgegevens te beschermen en wij eisen dat van partijen die in onze
                opdracht persoonsgegevens verwerken; Wij respecteren uw recht om ten alle tijden uw persoonsgegevens op te vragen, te corrigeren of te
                verwijderen.
            </p>
            <div className="policies__subtitle">Over BezuinigSlim:</div>
            <p>
                Door je aan te melden op onze website via het aanmeldformulier. Geef je BezuinigSlim het recht om je huidige abonnement bij jouw
                telecom provider te heronderhandelen. Wij hebben niet het recht om uw internet snelheid of andere aspecten van uw abonnement te
                veranderen. Wij heronderhandelen alleen een betere prijs. BezuinigSlim is een eenmanszaak met het adres Hunthum 39 3632 XM Nederland,
                geregistreerd bij de Kamer van Koophandel onder nummer 81985592.
            </p>
            <div className="policies__subtitle">Van wie worden er persoonsgegevens verwerkt?</div>
            <p>BezuinigSlim verwerkt persoonsgegevens van: Personen die zich aanmelden via het aanmeldformulier op de website van BezuinigSlim.</p>
            <div className="policies__subtitle">Voor welke doelen worden welke persoonsgegevens verwerkt?</div>
            <p>
                Voor het heronderhandelen van uw abonnement gebruiken wij de volgende gegevens: huidige aanbieder, voor- en achternaam, adres, e-mail
                adres, telefoonnummer, Iban nummer, aantal jaar klant bij telecombedrijf. Het doel hiervan is het heronderhandelen van uw abonnement
                met uw huidige aanbieder door BezuinigSlim, namens de gebruiker. Bij het invullen van het formulier gaat u ermee akkoord dat wij
                eenmalig uw abonnement heronderhandelen. Wanneer een onderhandeling onsuccesvol is, zal BezuinigSlim u hierover informeren via de
                mail. Als u weer uit contract bent bij uw aanbieder, zullen wij contact met u opnemen. Wij zullen u dan vragen of u opnieuw gebruik
                wilt maken van onze diensten.
            </p>
            <div className="policies__subtitle">Opslag van persoonsgegevens</div>
            <p>
                BezuinigSlim verwerkt persoonsgegevens op basis van de door u gegeven toestemming. U kunt uw gegeven toestemming ten alle tijden
                intrekken. BezuinigSlim bewaart uw persoonsgegevens nooit langer dan noodzakelijk is, voor het bereiken van de eerder genoemde doelen.
                BezuinigSlim verwijdert uw persoonsgegevens op het moment dat u uw toestemming heeft ingetrokken. Wij slaan uw persoonsgegevens op bij
                Microsoft en Google. Beide partijen zijn compliant met de AVG en GDPR wetgeving. Hierdoor bent u er zeker van dat uw persoonlijke
                gegevens veilig zijn opgeslagen.
            </p>
            <div className="policies__subtitle">Gegevens delen met derden</div>
            <p>
                BezuinigSlim zal uw persoonlijke gegevens nooit verkopen of misbruiken. Om een abonnement te onderhandelen, wordt informatie gedeeld
                met uw huidige aanbieder.
            </p>
            <div className="policies__subtitle">Het verbeteren van onze diensten en product</div>
            <p>
                We verzamelen uitsluitend eventgegevens om onze service te verbeteren. Daartoe verzamelen we automatisch informatie van uw browser of
                apparaat wanneer u onze website bezoekt. Deze informatie kan uw IP-adres, apparaat-ID en -type, uw browsertype en taal, het
                besturingssysteem dat door uw apparaat wordt gebruikt, toegangstijden en het verwijzende websiteadres omvatten. Dit maakt het mogelijk
                om uw gebruikerservaring aan te passen en te verbeteren op onze webpagina. We integreren zogeheten pixeltags (ook wel web bakens of
                clear GIF's genoemd) op webpagina's, advertenties en e-mails. Deze kleine, onzichtbare afbeeldingen worden gebruikt om toegang te
                krijgen tot cookies en gebruikersactiviteiten bij te houden (zoals hoe vaak een pagina wordt bekeken). Met deze technologie kunnen we
                leren welke blogs en pagina's het populairst zijn, zodat we meer van deze relevante content kunnen genereren. We gebruiken het ook om
                te bepalen welke e-mailberichten het meest worden gewaardeerd door onze gebruikers.
            </p>
            <div className="policies__subtitle">Evaluatie van de effectiviteit van onze advertenties:</div>
            <p>
                We kunnen gegevens delen met een aantal serviceproviders, reclamebedrijven en advertentienetwerken om onze advertenties te
                optimaliseren. Platforms waarmee we evenementgegevens kunnen delen, zijn onder meer Facebook, Mailchimp, Google, Microsoft. Daarnaast
                kunnen we persoonsgegevens delen. Het gaat hier uitsluitend om ID-nummer van je mobiele apparaat of je e-mailadres. Platforms waarmee
                we deze gegevens kunnen delen, zijn onder meer Facebook, Mailchimp, Pinterest en Snapchat. Hierdoor kunnen we ervoor zorgen dat we
                alleen advertenties tonen aan mensen die daadwerkelijk geïnteresseerd zijn in het gebruik van onze diensten.
            </p>
            <div className="policies__subtitle">Uw rechten:</div>
            <p>
                Er zijn een aantal rechten die uw toekomen: Recht op inzage, correctie, verwijdering: U heeft het recht om uw persoonsgegevens in te
                zien, te corrigeren of verwijderen.
            </p>
            <div className="policies__subtitle">Recht om vergeten te worden:</div>
            <p>
                In een aantal gevallen heeft u het recht om vergeten te worden. BezuinigSlim zal dan uw persoonsgegevens wissen en eventuele derden
                aan wie uw persoonsgegevens zijn verstrekt vragen dit ook te doen.
            </p>
            <div className="policies__subtitle">Recht op verzet:</div>
            <p>
                In bepaalde gevallen heeft u het recht om u te verzetten tegen de verwerking van uw persoonsgegevens. BezuinigSlim zal uw
                persoonsgegevens niet langer verwerken wanneer u het recht op verzet inroept.
            </p>
            <div className="policies__subtitle">Toestemming intrekken:</div>
            <p>
                Wanneer u toestemming heeft gegeven voor de verwerking van uw persoonsgegevens, kunt u de door u gegeven toestemming ten alle tijden
                intrekken. Let er wel op dat het intrekken van jouw toestemming geen terugwerkende kracht heeft. Als u een verzoek wilt indienen met
                betrekking tot bovenstaande rechten, kunt u een mail sturen naar support@BezuinigSlim.nl. Vermeld in de mail wat uw verzoek precies is
                en om welke persoonsgegevens het gaat. BezuinigSlim zal een verzoek binnen één maand behandelen. Wanneer het gaat om een complex
                verzoek kunnen hier echter maximaal twee maanden extra voor genomen worden. Mocht dit het geval zijn dan word u hierover wel binnen
                één maand geïnformeerd.
            </p>
            <div className="policies__subtitle">Cookies:</div>
            <p>Voor de website maakt BezuinigSlim gebruik van cookies. Meer informatie hierover is te vinden in ons Cookiebeleid.</p>
            <div className="policies__subtitle">Contact:</div>
            <p>info@bezuinigslim.nl</p>
            <div className="policies__subtitle">Klachten:</div>
            <p>In het geval van een klacht kan er contact op worden genomen via support@BezuinigSlim.nl.</p>
            <div className="policies__subtitle">Wijzigingen in deze Privacyverklaring:</div>
            <p>
                BezuinigSlim behoudt zich het recht voor de Privacyverklaring te wijzigen wanneer er interne of externe ontwikkelingen zijn. De meest
                recente versie kan altijd gevonden worden op{' '}
                <Link to="/privacyverklaring" className="inline-link" target="_blank">
                    https://www.bezuinigslim.nl/privacyverklaring
                </Link>
                . Gebruikers zullen altijd via de mail geïnformeerd worden wanneer deze Privacyverklaring gewijzigd wordt.
            </p>
        </>
    );
};

export default Privacy;
