import { ContactFormValueProps } from "./ContactForm";

export type ValidateFormProps = {
    name: string;
    approval: string;
    email: string;
}

const validateForm = (values: ContactFormValueProps) => {
    const errors = {} as ValidateFormProps;

    if (!values.name) {
        errors.name = 'Uw naam is vereist';
    }

    if(!values.approval) {
        errors.approval = 'U dient akkoord te gaan';
    }

    if (!values.email) {
        errors.email = 'E-mail is vereist';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Dit e-mailadres is niet valide';
    }

    return errors;
}

export { validateForm };
