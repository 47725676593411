import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { headerRoutesTypes, RouteType } from '../App';
import { useWindowWidth } from '@react-hook/window-size';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useScrollPosition from '@react-hook/window-scroll';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

type HeaderProps = {
    activeItem?: string;
    routes: RouteType[];
};

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
    const windowWith = useWindowWidth();
    const scrollY = useScrollPosition(60);

    const isMobile = windowWith < 768;
    const [toggleMenu, setToggleMenu] = useState(false);

    const itemIsActive = (item: string): boolean => {
        if (!props.activeItem) {
            return false;
        } else return props.activeItem.toLowerCase().includes(item.toLowerCase());
    };

    const blackBackgroundActive = () => {
        return (
            props.activeItem &&
            (props.activeItem === '/' + headerRoutesTypes.HOME.toLowerCase() ||
                props.activeItem === '/' ||
                props.activeItem === '/' + headerRoutesTypes.SIGNUP.toLowerCase())
        );
    };

    return (
        <nav className={`header ${scrollY <= 0 && blackBackgroundActive() && !isMobile ? 'header__transparent' : ''}`}>
            <div className={`container ${!isMobile ? 'flex' : 'header__nav-mobile'}`}>
                <p className={`header__logo-text ${isMobile && toggleMenu ? 'header__logo-text--hidden' : ''}`}>
                    <Link to="/">BezuinigSlim</Link>
                </p>
                <ul className={`header__links ${isMobile && !toggleMenu ? 'header__links--hidden' : ''}`}>
                    {props.routes &&
                        props.routes.map((route) => {
                            return (
                                <li key={route.name}>
                                    <Link to={route.path} className="header__link" onClick={() => isMobile && setToggleMenu(false)}>
                                        {itemIsActive(route.name) && <div className="header__link--active"></div>}
                                        {route.name}
                                    </Link>
                                </li>
                            );
                        })}
                    <li>
                        <Link to="/aanmelden" className="header__cta" onClick={() => isMobile && setToggleMenu(false)}>
                            Aanmelden
                        </Link>
                    </li>
                </ul>

                {isMobile && (
                    <div className="header__toggle" onClick={() => setToggleMenu(!toggleMenu)}>
                        <FontAwesomeIcon icon={faBars} className={`${toggleMenu ? 'header__svg--hidden' : ''}`} />
                        <FontAwesomeIcon icon={faTimes} className={`${!toggleMenu ? 'header__svg--hidden' : ''}`} />
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Header;
