import React from 'react';
import { Helmet } from 'react-helmet-async';
import CookiePolicy from '../Policies/CookiePolicy';
import DataPolicy from '../Policies/DataPolicy';
import { PolicyTypes } from '../Policies/PolicyTypes';
import Privacy from '../Policies/Privacy';
import Terms from '../Policies/Terms';

export type PoliciesPageProps = {
    name: string;
};

const PoliciesPage: React.FC<PoliciesPageProps> = (props: PoliciesPageProps) => {
    const getPolicyToRender = () => {
        switch (props.name) {
            case PolicyTypes.TERMS:
                return <Terms />;
            case PolicyTypes.PRIVACY:
                return <Privacy />;
            case PolicyTypes.COOKIES:
                return <CookiePolicy />;
            case PolicyTypes.DATA:
                return <DataPolicy />;
            default:
                return;
        }
    };

    return (
        <>
            <Helmet>
                <title>BezuinigSlim - {props.name}</title>
                <meta
                    name="description"
                    content="Op deze pagina zijn onze algemene voorwaarden, privacyverklaring, cookie- en databeleid zichtbaar.
                    Hierin staan de rechten en plichten, van zoal de klant als BezuinigSlim."
                />
            </Helmet>
            <div className="flex">
                <div className="container policies">
                    <h1>{props.name}</h1>
                    {getPolicyToRender()}
                </div>
            </div>
        </>
    );
};

export default PoliciesPage;
